.dropdown {
  position: relative;
  min-width: 149px;
}

.dropdown__selected {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  border-radius: 8px;
  box-shadow: var(--box-shadow-menu);
  background-color: var(--white);
}

.dropdown-selected__icon {
  transform-origin: center;
  transform: rotate(180deg);
}

.dropdown__content {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 8px));
  width: 255px;
  border-radius: 8px;
  box-shadow: var(--box-shadow-classic);
  background-color: var(--white);
  display: grid;
  padding: 8px;
  visibility: hidden;
}

.dropdown__content--visible {
  visibility: visible;
}

.dropdown-option {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding: 14px 16px;
}

.dropdown-selected:hover,
.dropdown-selected:not(:disabled).focus-visible,
.dropdown-option:not(:disabled).focus-visible,
.dropdown-option:hover {
  background: rgba(23, 25, 45, 0.05);
  outline: none;
}

.dropdown-selected:not(:disabled).focus-visible {
  outline: 8px solid rgba(23, 25, 45, 0.2);
  outline-offset: 0;
}

.dropdown-option__icon {
  display: flex;
}
