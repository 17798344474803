.modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--black70);
  backdrop-filter: blur(4px);
  overflow-y: auto;
  padding: 16px;
  z-index: var(--z-index-modal-overlay);

  display: grid;
  place-items: center;
}

.modal-content__close-btn {
  color: var(--white);
  position: relative;
  width: 24px;
  height: 24px;
  font-size: 24px;
  padding: 10px 0 16px;
  box-sizing: content-box;
  display: grid;
  margin-left: auto;
}

.modal-content__close-btn:hover,
.modal-content__close-btn:active {
  color: var(--white50);
}

.modal-content__close-btn svg {
  width: 100%;
  height: 100%;
}
