.details-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.details-item {
  display: grid;
  grid-template-columns: minmax(131px, min-content) 1fr;
  align-items: start;
  column-gap: 65px;
}

.item-key {
  display: inline-flex;
  white-space: nowrap;
  gap: 12px;
}

.item-value {
  word-break: break-all;
}

.tooltip-container {
  position: relative;
}

.tooltip-container__content {
  display: none;
  position: absolute;
  white-space: normal;
  width: 258px;
  padding: 6px 12px;
  border-radius: 8px;
  background: #3d3d3d;
  color: var(--white);
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  transform: translate(-50%, calc(100% + 16px));
}

.tooltip-container__button {
  display: inline-grid;
  place-items: center;
  width: 19px;
  height: 19px;
  background-color: var(--lightGrey);
  color: var(--black50);
  border-radius: 50%;
}

.tooltip-container__button:focus + .tooltip-container__content,
.tooltip-container__button:hover + .tooltip-container__content {
  display: block;
}
