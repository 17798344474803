.checked-mark {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-grid;
  place-items: center;
  padding: 6px;
  background-color: var(--green);
}

.counter-mark {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-grid;
  place-items: center;
  background-color: var(--black);
  color: var(--white);
}

.status {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--green);
}

.next-steps {
  list-style: none;
}

.next-steps__item {
  display: flex;
  align-items: center;
}

.next-steps__item > span {
  margin-right: 12px;
}
