/* Base button styles */
.base-btn {
  font-family: var(--main-font);
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 140%;

  appearance: none;
  border: none;
  display: inline-flex;

  border-radius: 6px;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  text-decoration: none;
}

.base-btn:not(:disabled).focus-visible,
.base-btn:not(:disabled):focus-visible {
  outline: 2px solid var(--blue);
  outline-offset: 1px;
}
.base-btn:not(:disabled):not([disabled]):hover {
  cursor: pointer;
}

/* Default button styles */
.default-btn {
  --background-color: var(--blue);
  --color: var(--white);
  --focus-color: var(--blue20);

  display: flex;
  flex-direction: row;
  width: fit-content;
  gap: 12px;
  border-radius: 8px;

  white-space: break-spaces;
  transition: background-color 0.2s linear;

  background-color: var(--background-color);
  color: var(--color);
}

@media screen and (max-width: 640px) {
  .default-btn {
    min-width: fit-content;
  }
}

.default-btn:not(.default-btn--disabled):is(:hover, .focus-visible)
  .default-btn__content {
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

.default-btn:not(.default-btn--disabled):is(:hover, .focus-visible)
  .default-btn__children,
.default-btn--size-sm .default-btn__children {
  transform: translateX(0);
}

/* Full width size */
.default-btn.default-btn--size-full-width {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
/* SM size */
.default-btn.default-btn--size-sm {
  padding: 8px 20px;
  min-width: fit-content;
  max-height: 40px;
  min-height: 40px;
}
/* MD size */
.default-btn.default-btn--size-md {
  padding: 14px 28px;
  max-height: 52px;
  min-height: 52px;
  min-width: 168px;
}

/* Default state */
.default-btn:not(.default-btn--disabled).default-btn--color-primary {
  --background-color: var(--blue);
  --color: var(--white);
}
.default-btn:not(.default-btn--disabled).default-btn--color-white {
  --background-color: var(--white);
  --color: var(--black);
}
.default-btn:not(.default-btn--disabled).default-btn--color-black {
  --background-color: var(--black);
  --color: var(--white);
}
/* Disabled state */
.default-btn.default-btn--disabled.default-btn--color-primary {
  --background-color: var(--blue50);
  --color: var(--white50);
  cursor: default;
}
.default-btn.default-btn--disabled.default-btn--color-white {
  --background-color: var(--white50);
  --color: var(--black50);
  cursor: default;
}
.default-btn.default-btn--disabled.default-btn--color-black {
  --background-color: var(--black50);
  --color: var(--white50);
  cursor: default;
}
/* Active state */
.default-btn:not(:disabled):not(
    [data-disabled="true"]
  ).default-btn--color-primary:active {
  background-image: linear-gradient(
    to bottom right,
    var(--blue100),
    var(--black15)
  );
  --color: var(--white50);
}
.default-btn:not(:disabled):not(
    [data-disabled="true"]
  ).default-btn--color-white:active {
  background-image: linear-gradient(
    to bottom right,
    var(--white100),
    var(--black5)
  );
  --color: var(--black50);
}
.default-btn:not(:disabled):not(
    [data-disabled="true"]
  ).default-btn--color-black:active {
  background-image: linear-gradient(
    to bottom right,
    var(--black100),
    var(--black15)
  );
  --color: var(--white50);
}
/* Focus state */
.default-btn:not(.default-btn--disabled):not(
    [data-disabled="true"]
  ).focus-visible {
  outline-width: 8px;
  outline-offset: 0;
  outline-style: solid;
  outline-color: var(--focus-color);
}
.default-btn:not(.default-btn--disabled):not(
    [data-disabled="true"]
  ).focus-visible.default-btn--color-white {
  --focus-color: var(--white20);
}
.default-btn:not(.default-btn--disabled):not(
    [data-disabled="true"]
  ).focus-visible.default-btn--color-black {
  --focus-color: var(--black20);
}

/* Loading variant */
.default-btn.default-btn--color-primary.default-btn--loading {
  --background-color: var(--blue);
}
.default-btn.default-btn--color-white.default-btn--loading {
  --background-color: var(--white);
}
.default-btn.default-btn--color-black.default-btn--loading {
  --background-color: var(--black);
}
/* Success variant */
.default-btn.default-btn--color-primary.default-btn--success {
  --background-color: var(--green);
}
.default-btn.default-btn--color-white.default-btn--success {
  --background-color: var(--green);
}
.default-btn.default-btn--color-black.default-btn--success {
  --background-color: var(--green);
}
/* Error variant */
.default-btn.default-btn--color-primary.default-btn--error {
  --background-color: var(--red);
}
.default-btn.default-btn--color-white.default-btn--error {
  --background-color: var(--red);
}
.default-btn.default-btn--color-black.default-btn--error {
  --background-color: var(--red);
}

/* Default button children wrapper */
.default-btn__children {
  --children-color: inherit;
  display: flex;
  gap: 12px;
  transform: translateX(12px);
  transition: transform 0.2s ease-in;
  align-items: center;

  color: var(--children-color);
}

.default-btn__icon-wrapper {
  display: grid;
  place-items: center;
}

/* Default button content wrapper */
.default-btn__content {
  --content-color: inherit;
  display: flex;
  color: var(--content-color);
  opacity: 0;
}

.default-btn__content.default-btn__content--black {
  --content-color: var(--black);
}
.default-btn__content.default-btn__content--white {
  --content-color: var(--white);
}
.default-btn__content.default-btn__content--visible {
  opacity: 1;
}
.default-btn__content:empty {
  display: none;
}

/* Spinner */
.spinner {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  animation: var(--rotate-animation);

  transform-origin: center;
}

.arrow {
  width: 12px;
}

.checked {
  width: 16px;
}
