.base-card {
  border-radius: var(--card-border-radius);
  background-color: var(--white);
  box-shadow: var(--box-shadow-classic);
  padding: 32px;
}

.base-card.base-card--md-size {
  max-width: 568px;
}

.base-card.base-card--sm-size {
  max-width: 400px;
  width: 400px;
}
