*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  font-family: var(--main-font);
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

a {
  text-underline: none;
}

html,
body {
  scroll-behavior: smooth !important;
}

.container {
  max-width: 1200px;
  padding: 0 16px;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  scroll-padding-top: 100px;
}

/* END of CSS RESET */

* {
  font-family: var(--main-font);
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
.visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.noscroll {
  overflow: hidden;
}

html,
body {
  background-color: var(--ultraLightGrey);
}

body {
  padding-right: var(--scrollbar-width);
  padding-bottom: 50px;
}

.disclaimer-card {
  background-color: var(--ultraLightGrey);
  border-radius: 8px;
  padding: 15px 19px;
}

.disclaimer-card.disclaimer-card--success {
  background-color: var(--green6);
}

.h2 {
  font-weight: 800;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.8px;
}

.h3 {
  font-weight: 800;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.4px;
}

.h4 {
  font-weight: 800;
  font-size: 24px;
  line-height: 33.6px;
  letter-spacing: -0.6px;
}

.body-s {
  font-weight: 400;
  font-size: 15px;
  line-height: 160%;
}

.body-s-bold {
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
}

.body-m-semibold {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.body-m {
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
}

.body-xl-bold {
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
}

.description {
  font-weight: 500;
  font-size: 13px;
  line-height: 160%;
}

.description-bold {
  font-weight: 700;
  font-size: 13px;
  line-height: 160%;
}

.app-link {
  color: var(--blue);
  text-decoration: none;
}

.app-link[href] {
  cursor: pointer;
}

.pt8 {
  padding-top: 8px;
}
.pb8 {
  padding-bottom: 8px;
}
.pt16 {
  padding-top: 16px;
}
.pb16 {
  padding-bottom: 16px;
}
.pt24 {
  padding-top: 24px;
}
.pb24 {
  padding-bottom: 24px;
}
.pt32 {
  padding-top: 32px;
}
.pb32 {
  padding-bottom: 32px;
}
.mt8 {
  margin-top: 8px;
}
.mb8 {
  margin-bottom: 8px;
}
.mt16 {
  margin-top: 16px;
}
.mb16 {
  margin-bottom: 16px;
}
.mt24 {
  margin-top: 24px;
}
.mb24 {
  margin-bottom: 24px;
}
.mt32 {
  margin-top: 32px;
}
.mb32 {
  margin-bottom: 32px;
}
.ma {
  margin: auto;
}
.tac {
  text-align: center;
}

.link-w-icon {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
    transform-origin: center;
  }
  to {
    transform: rotate(360deg);
    transform-origin: center;
  }
}
