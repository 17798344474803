.skeleton-line {
  height: 12px;
  margin-top: 4.4px;
  margin-bottom: 4.4px;
  border-radius: 33px;
  background-color: var(--lightGrey);
}

.skeleton-line:not(:last-child) {
  margin-bottom: 10px;
}
