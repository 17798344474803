.icon-wrapper {
  color: var(--blue);
  animation: var(--rotate-animation);

  width: 38px;
  height: 38px;

  display: inline-block;
}

.icon-wrapper > svg {
  width: 100%;
  height: 100%;
}
