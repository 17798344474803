@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("Inter-Light.5aab02e1.ttf") format("truetype");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Regular.74535f8a.ttf") format("truetype");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("Inter-Medium.de3d51e6.ttf") format("truetype");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("Inter-SemiBold.f13c74ed.ttf") format("truetype");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("Inter-Bold.2a398d70.ttf") format("truetype");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("Inter-ExtraBold.10a80f02.ttf") format("truetype");
}

.base-btn {
  font-family: var(--main-font);
  appearance: none;
  text-align: center;
  box-sizing: border-box;
  background-color: #0000;
  border: none;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  text-decoration: none;
  display: inline-flex;
}

.base-btn:not(:disabled).focus-visible, .base-btn:not(:disabled):focus-visible {
  outline: 2px solid var(--blue);
  outline-offset: 1px;
}

.base-btn:not(:disabled):not([disabled]):hover {
  cursor: pointer;
}

.default-btn {
  --background-color: var(--blue);
  --color: var(--white);
  --focus-color: var(--blue20);
  width: -moz-fit-content;
  width: fit-content;
  white-space: break-spaces;
  background-color: var(--background-color);
  color: var(--color);
  border-radius: 8px;
  flex-direction: row;
  gap: 12px;
  transition: background-color .2s linear;
  display: flex;
}

@media screen and (max-width: 640px) {
  .default-btn {
    min-width: -moz-fit-content;
    min-width: fit-content;
  }
}

.default-btn:not(.default-btn--disabled):is(:hover, .focus-visible) .default-btn__content {
  opacity: 1;
  transition: opacity .2s ease-in;
}

.default-btn:not(.default-btn--disabled):is(:hover, .focus-visible) .default-btn__children, .default-btn--size-sm .default-btn__children {
  transform: translateX(0);
}

.default-btn.default-btn--size-full-width {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.default-btn.default-btn--size-sm {
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-height: 40px;
  min-height: 40px;
  padding: 8px 20px;
}

.default-btn.default-btn--size-md {
  max-height: 52px;
  min-height: 52px;
  min-width: 168px;
  padding: 14px 28px;
}

.default-btn:not(.default-btn--disabled).default-btn--color-primary {
  --background-color: var(--blue);
  --color: var(--white);
}

.default-btn:not(.default-btn--disabled).default-btn--color-white {
  --background-color: var(--white);
  --color: var(--black);
}

.default-btn:not(.default-btn--disabled).default-btn--color-black {
  --background-color: var(--black);
  --color: var(--white);
}

.default-btn.default-btn--disabled.default-btn--color-primary {
  --background-color: var(--blue50);
  --color: var(--white50);
  cursor: default;
}

.default-btn.default-btn--disabled.default-btn--color-white {
  --background-color: var(--white50);
  --color: var(--black50);
  cursor: default;
}

.default-btn.default-btn--disabled.default-btn--color-black {
  --background-color: var(--black50);
  --color: var(--white50);
  cursor: default;
}

.default-btn:not(:disabled):not([data-disabled="true"]).default-btn--color-primary:active {
  background-image: linear-gradient(to bottom right, var(--blue100), var(--black15));
  --color: var(--white50);
}

.default-btn:not(:disabled):not([data-disabled="true"]).default-btn--color-white:active {
  background-image: linear-gradient(to bottom right, var(--white100), var(--black5));
  --color: var(--black50);
}

.default-btn:not(:disabled):not([data-disabled="true"]).default-btn--color-black:active {
  background-image: linear-gradient(to bottom right, var(--black100), var(--black15));
  --color: var(--white50);
}

.default-btn:not(.default-btn--disabled):not([data-disabled="true"]).focus-visible {
  outline-offset: 0;
  outline-width: 8px;
  outline-style: solid;
  outline-color: var(--focus-color);
}

.default-btn:not(.default-btn--disabled):not([data-disabled="true"]).focus-visible.default-btn--color-white {
  --focus-color: var(--white20);
}

.default-btn:not(.default-btn--disabled):not([data-disabled="true"]).focus-visible.default-btn--color-black {
  --focus-color: var(--black20);
}

.default-btn.default-btn--color-primary.default-btn--loading {
  --background-color: var(--blue);
}

.default-btn.default-btn--color-white.default-btn--loading {
  --background-color: var(--white);
}

.default-btn.default-btn--color-black.default-btn--loading {
  --background-color: var(--black);
}

.default-btn.default-btn--color-primary.default-btn--success, .default-btn.default-btn--color-white.default-btn--success, .default-btn.default-btn--color-black.default-btn--success {
  --background-color: var(--green);
}

.default-btn.default-btn--color-primary.default-btn--error, .default-btn.default-btn--color-white.default-btn--error, .default-btn.default-btn--color-black.default-btn--error {
  --background-color: var(--red);
}

.default-btn__children {
  --children-color: inherit;
  color: var(--children-color);
  align-items: center;
  gap: 12px;
  transition: transform .2s ease-in;
  display: flex;
  transform: translateX(12px);
}

.default-btn__icon-wrapper {
  place-items: center;
  display: grid;
}

.default-btn__content {
  --content-color: inherit;
  color: var(--content-color);
  opacity: 0;
  display: flex;
}

.default-btn__content.default-btn__content--black {
  --content-color: var(--black);
}

.default-btn__content.default-btn__content--white {
  --content-color: var(--white);
}

.default-btn__content.default-btn__content--visible {
  opacity: 1;
}

.default-btn__content:empty {
  display: none;
}

.spinner {
  width: 16px;
  height: 16px;
  animation: var(--rotate-animation);
  transform-origin: center;
  align-items: center;
  display: flex;
}

.arrow {
  width: 12px;
}

.checked {
  width: 16px;
}

.base-card {
  border-radius: var(--card-border-radius);
  background-color: var(--white);
  box-shadow: var(--box-shadow-classic);
  padding: 32px;
}

.base-card.base-card--md-size {
  max-width: 568px;
}

.base-card.base-card--sm-size {
  max-width: 400px;
  width: 400px;
}

.skeleton-line {
  height: 12px;
  background-color: var(--lightGrey);
  border-radius: 33px;
  margin-top: 4.4px;
  margin-bottom: 4.4px;
}

.skeleton-line:not(:last-child) {
  margin-bottom: 10px;
}

.details-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.details-item {
  grid-template-columns: minmax(131px, min-content) 1fr;
  align-items: start;
  column-gap: 65px;
  display: grid;
}

.item-key {
  white-space: nowrap;
  gap: 12px;
  display: inline-flex;
}

.item-value {
  word-break: break-all;
}

.tooltip-container {
  position: relative;
}

.tooltip-container__content {
  white-space: normal;
  width: 258px;
  color: var(--white);
  background: #3d3d3d;
  border-radius: 8px;
  margin: auto;
  padding: 6px 12px;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translate(-50%, calc(100% + 16px));
}

.tooltip-container__button {
  width: 19px;
  height: 19px;
  background-color: var(--lightGrey);
  color: var(--black50);
  border-radius: 50%;
  place-items: center;
  display: inline-grid;
}

.tooltip-container__button:focus + .tooltip-container__content, .tooltip-container__button:hover + .tooltip-container__content {
  display: block;
}

._2COeFG_AccountContent {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

._2COeFG_AccountContent__balanceLabel {
  padding-bottom: 4px;
}

._2COeFG_AccountContent__userProfile {
  height: 55px;
  width: 177px;
  border: 1px solid var(--blue);
  background-color: var(--ultraLightGrey);
  border-radius: 100px;
  padding: 9px;
  display: flex;
}

._2COeFG_AccountContent__userProfile:not(:disabled).focus-visible {
  outline: 8px solid var(--blue20);
  outline-offset: 0;
}

._2COeFG_AccountContent__userProfile:hover {
  background-color: var(--lightGrey);
}

._2COeFG_AccountContent__address {
  margin-left: auto;
}

._2COeFG_AccountContent__avatar {
  width: 32px;
  height: 32px;
  background-color: gray;
  border-radius: 50%;
  margin-left: auto;
  overflow: hidden;
}

.modal-overlay {
  background-color: var(--black70);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  z-index: var(--z-index-modal-overlay);
  place-items: center;
  padding: 16px;
  display: grid;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.modal-content__close-btn {
  color: var(--white);
  width: 24px;
  height: 24px;
  box-sizing: content-box;
  margin-left: auto;
  padding: 10px 0 16px;
  font-size: 24px;
  display: grid;
  position: relative;
}

.modal-content__close-btn:hover, .modal-content__close-btn:active {
  color: var(--white50);
}

.modal-content__close-btn svg {
  width: 100%;
  height: 100%;
}

.icon-wrapper {
  color: var(--blue);
  animation: var(--rotate-animation);
  width: 38px;
  height: 38px;
  display: inline-block;
}

.icon-wrapper > svg {
  width: 100%;
  height: 100%;
}



.exception-modal__actions {
  gap: 16px;
  display: grid;
}

.loading {
  animation: var(--rotate-animation);
  color: var(--black20);
  transform-origin: center;
}

.checked-mark {
  width: 24px;
  height: 24px;
  background-color: var(--green);
  border-radius: 50%;
  place-items: center;
  padding: 6px;
  display: inline-grid;
}

.counter-mark {
  width: 24px;
  height: 24px;
  background-color: var(--black);
  color: var(--white);
  border-radius: 50%;
  place-items: center;
  display: inline-grid;
}

.status {
  color: var(--green);
  align-items: center;
  gap: 10px;
  display: flex;
}

.next-steps {
  list-style: none;
}

.next-steps__item {
  align-items: center;
  display: flex;
}

.next-steps__item > span {
  margin-right: 12px;
}

.dropdown {
  min-width: 149px;
  position: relative;
}

.dropdown__selected {
  width: 100%;
  box-shadow: var(--box-shadow-menu);
  background-color: var(--white);
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  display: flex;
}

.dropdown-selected__icon {
  transform-origin: center;
  transform: rotate(180deg);
}

.dropdown__content {
  width: 255px;
  box-shadow: var(--box-shadow-classic);
  background-color: var(--white);
  visibility: hidden;
  border-radius: 8px;
  padding: 8px;
  display: grid;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 8px));
}

.dropdown__content--visible {
  visibility: visible;
}

.dropdown-option {
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 14px 16px;
  display: flex;
}

.dropdown-selected:hover, .dropdown-selected:not(:disabled).focus-visible, .dropdown-option:not(:disabled).focus-visible, .dropdown-option:hover {
  background: #17192d0d;
  outline: none;
}

.dropdown-selected:not(:disabled).focus-visible {
  outline-offset: 0;
  outline: 8px solid #17192d33;
}

.dropdown-option__icon {
  display: flex;
}

.header {
  height: 100px;
  max-width: 568px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.noop-view {
  height: calc(100vh - 36px);
  align-content: center;
  align-items: center;
  display: grid;
}

.descr {
  color: var(--black50);
}

.DxZoLa_WalletsCard {
  width: 488px;
}

.DxZoLa_WalletsCard__description {
  color: var(--black50);
}

.DxZoLa_WalletsCard__message {
  color: var(--red);
}

.disclaimer-card.DxZoLa_WalletsCard__contact {
  gap: 11px;
  padding: 24px;
  display: flex;
}

.DxZoLa_WalletList {
  justify-content: stretch;
  gap: 16px;
  display: flex;
}

.DxZoLa_WalletBtn {
  background-color: var(--ultraLightGrey);
  width: 100%;
  border-radius: 8px;
  justify-items: center;
  row-gap: 10px;
  padding: 24px;
  display: grid;
}

.DxZoLa_WalletBtn--error {
  outline: 1px solid var(--red);
}

.DxZoLa_WalletBtn:not(:disabled):active {
  background-color: var(--lightGrey);
}

.DxZoLa_WalletBtn:not(:disabled).focus-visible {
  outline-offset: 0;
  outline-width: 8px;
  outline-style: solid;
  outline-color: var(--blue20);
}

.DxZoLa_WalletBtn__icon {
  width: 52px;
  height: 52px;
  background-color: currentColor;
  border-radius: 50%;
  place-items: center;
  padding: 10px;
  display: grid;
}

.DxZoLa_WalletBtn__icon svg {
  max-width: 100%;
  max-height: 100%;
}

._4eydNG_BtnText {
  color: var(--blue);
  flex-wrap: nowrap;
  align-items: center;
  gap: 11px;
  padding: 8px;
  display: flex;
}

._4eydNG_BtnText__icon {
  max-width: 18px;
  aspect-ratio: 1 / 1;
  place-items: center;
  display: grid;
}

._4eydNG_BtnText__icon > * {
  max-width: 100%;
  max-width: 100%;
}

.Wc3LUW_AccountCard {
  width: 488px;
}

.Wc3LUW_AccountCard__walletRow {
  color: var(--black50);
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding-bottom: 11px;
  display: flex;
}

.Wc3LUW_AccountCard__userRow {
  align-items: center;
  gap: 13px;
  padding-bottom: 16px;
  display: flex;
}

.Wc3LUW_WalletAvatar {
  width: 32px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
}

.Wc3LUW_WalletAvatar:empty {
  background-color: var(--grey);
}

.Wc3LUW_AccountCard__actionsRow {
  align-items: center;
  gap: 16px;
  display: flex;
}

.gsZaFq_LedgerConnection__instructions {
  height: 88px;
  line-height: 0;
  position: relative;
  overflow: hidden;
}

.gsZaFq_LedgerConnection__instructions video {
  width: 469px;
  height: 116px;
  position: absolute;
  top: 50%;
  left: -42px;
  transform: translateY(-50%);
}

:root {
  --white100: #fff;
  --white70: #ffffffb3;
  --white50: #ffffff80;
  --white20: #fff3;
  --white10: #ffffff1a;
  --white5: #ffffff0d;
  --white: var(--white100);
  --black100: #0b0b0b;
  --black80: #17192dcc;
  --black70: #17192db3;
  --black50: #17192d80;
  --black40: #17192d66;
  --black30: #17192d4d;
  --black20: #17192d33;
  --black15: #17192d26;
  --black10: #17192d1a;
  --black5: #17192d0d;
  --black: var(--black100);
  --blue100: #1347ff;
  --blue70: #1347ffb3;
  --blue50: #1347ff80;
  --blue20: #1347ff33;
  --blue6: #1347ff0f;
  --blue: var(--blue100);
  --green100: #25cb51;
  --green6: #25cb510f;
  --green: var(--green100);
  --orange: #ff7b02;
  --pink: #e6007a;
  --red: #d70808;
  --grey: #d7d7d7;
  --darkGrey: #3d3d3d;
  --lightGrey: #f2f1f3;
  --ultraLightGrey: #f8f8f8;
  --main-font: "Inter", sans-serif;
  --box-shadow-classic: 0 7px 137px #0e26641a;
  --box-shadow-menu: 0px 7px 128px #0e1d4442;
  --card-border-radius: 24px;
  --z-index-modal-overlay: 10;
  --rotate-animation: rotate 1s linear infinite;
}

*, :before, :after {
  box-sizing: border-box;
  font-family: var(--main-font);
  margin: 0;
  padding: 0;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

a {
  text-underline: none;
}

html, body {
  scroll-behavior: smooth !important;
}

.container {
  max-width: 1200px;
  height: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 16px;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  scroll-padding-top: 100px;
}

* {
  font-family: var(--main-font);
  box-sizing: border-box;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}

.visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.noscroll {
  overflow: hidden;
}

html, body {
  background-color: var(--ultraLightGrey);
}

body {
  padding-right: var(--scrollbar-width);
  padding-bottom: 50px;
}

.disclaimer-card {
  background-color: var(--ultraLightGrey);
  border-radius: 8px;
  padding: 15px 19px;
}

.disclaimer-card.disclaimer-card--success {
  background-color: var(--green6);
}

.h2 {
  letter-spacing: -.8px;
  font-size: 32px;
  font-weight: 800;
  line-height: 120%;
}

.h3 {
  letter-spacing: -.4px;
  font-size: 32px;
  font-weight: 800;
  line-height: 120%;
}

.h4 {
  letter-spacing: -.6px;
  font-size: 24px;
  font-weight: 800;
  line-height: 33.6px;
}

.body-s {
  font-size: 15px;
  font-weight: 400;
  line-height: 160%;
}

.body-s-bold {
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
}

.body-m-semibold {
  letter-spacing: -.3px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.body-m {
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}

.body-xl-bold {
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
}

.description {
  font-size: 13px;
  font-weight: 500;
  line-height: 160%;
}

.description-bold {
  font-size: 13px;
  font-weight: 700;
  line-height: 160%;
}

.app-link {
  color: var(--blue);
  text-decoration: none;
}

.app-link[href] {
  cursor: pointer;
}

.pt8 {
  padding-top: 8px;
}

.pb8 {
  padding-bottom: 8px;
}

.pt16 {
  padding-top: 16px;
}

.pb16 {
  padding-bottom: 16px;
}

.pt24 {
  padding-top: 24px;
}

.pb24 {
  padding-bottom: 24px;
}

.pt32 {
  padding-top: 32px;
}

.pb32 {
  padding-bottom: 32px;
}

.mt8 {
  margin-top: 8px;
}

.mb8 {
  margin-bottom: 8px;
}

.mt16 {
  margin-top: 16px;
}

.mb16 {
  margin-bottom: 16px;
}

.mt24 {
  margin-top: 24px;
}

.mb24 {
  margin-bottom: 24px;
}

.mt32 {
  margin-top: 32px;
}

.mb32 {
  margin-bottom: 32px;
}

.ma {
  margin: auto;
}

.tac {
  text-align: center;
}

.link-w-icon {
  align-items: center;
  gap: 8px;
  display: inline-flex;
}

@keyframes rotate {
  from {
    transform-origin: center;
    transform: rotate(0);
  }

  to {
    transform-origin: center;
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=index.df2e4d3e.css.map */
