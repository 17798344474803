.noop-view {
  height: calc(100vh - 36px);
  display: grid;
  align-items: center;
  align-content: center;
}

.descr {
  color: var(--black50);
}
