:root {
  --white100: rgba(255, 255, 255, 1);
  --white70: rgba(255, 255, 255, 0.7);
  --white50: rgba(255, 255, 255, 0.5);
  --white20: rgba(255, 255, 255, 0.2);
  --white10: rgba(255, 255, 255, 0.1);
  --white5: rgba(255, 255, 255, 0.05);
  --white: var(--white100);

  --black100: rgb(11, 11, 11);
  --black80: rgba(23, 25, 45, 0.8);
  --black70: rgba(23, 25, 45, 0.7);
  --black50: rgba(23, 25, 45, 0.5);
  --black40: rgba(23, 25, 45, 0.4);
  --black30: rgba(23, 25, 45, 0.3);
  --black20: rgba(23, 25, 45, 0.2);
  --black15: rgba(23, 25, 45, 0.15);
  --black10: rgba(23, 25, 45, 0.1);
  --black5: rgba(23, 25, 45, 0.05);
  --black: var(--black100);

  --blue100: rgba(19, 71, 255, 1);
  --blue70: rgba(19, 71, 255, 0.7);
  --blue50: rgba(19, 71, 255, 0.5);
  --blue20: rgba(19, 71, 255, 0.2);
  --blue6: rgba(19, 71, 255, 0.06);
  --blue: var(--blue100);

  --green100: #25cb51;
  --green6: rgba(37, 203, 81, 0.06);
  --green: var(--green100);

  --orange: #ff7b02;
  --pink: #e6007a;
  --red: #d70808;
  --grey: #d7d7d7;
  --darkGrey: #3d3d3d;
  --lightGrey: #f2f1f3;
  --ultraLightGrey: #f8f8f8;

  --main-font: "Inter", sans-serif;

  --box-shadow-classic: 0 7px 137px rgba(14, 38, 100, 0.1);
  --box-shadow-menu: 0px 7px 128px rgba(14, 29, 68, 0.26);

  --card-border-radius: 24px;

  --z-index-modal-overlay: 10;

  --rotate-animation: rotate 1s linear infinite;
}
